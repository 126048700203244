import { IBrand, IUser } from "../../context/models";


export const brandListMock: IBrand[] = [
    {
      title: 'FGI Employees',
      logo: '/images/employees.svg',
      primaryColor: '',
      secondaryColor: '',
      heroImage: '',
      nodeAlias: '/Employees',
    },
    {
      title: 'Licenses',
      logo: '/images/licenses.svg',
      primaryColor: '',
      secondaryColor: '',
      heroImage: '',
      nodeAlias: '/Licenses',
    },
    {
      title: 'Harvxtra',
      logo: '/images/harvxtra.png',
      primaryColor: '',
      secondaryColor: '',
      heroImage: '',
      nodeAlias: '/Harvxtra',
    },
    {
      title: 'Roundup Ready',
      logo: '/images/roundup-ready.png',
      primaryColor: '',
      secondaryColor: '',
      heroImage: '',
      nodeAlias: '/Roundup',
    },
    {
      title: 'Americas Alfalfa',
      logo: '/images/americas-alfalfa.png',
      primaryColor: '',
      secondaryColor: '',
      heroImage: '',
      nodeAlias: '/AA',
    },
    {
      title: 'Nexgrow',
      logo: '/images/plant-nexgrow.png',
      primaryColor: '',
      secondaryColor: '',
      heroImage: '',
      nodeAlias: '/Nexgrow',
    },
    {
      title: 'WL Alfalfa',
      logo: '/images/wl-alfalfa.png',
      primaryColor: '',
      secondaryColor: '',
      heroImage: '',
      nodeAlias: '/WLA',
    }
  ]

  export const userMock: IUser = {
    name: 'Rama'
  }