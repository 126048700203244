import React, {
    createContext,
    PropsWithChildren,
    useContext,
    useState,
  } from 'react';
import { IBrand } from './models';
import { brandListMock } from '../test/mock/mockData';

  export interface IAppContext {
    brandList: IBrand[];
    token: string;
    setToken: (state: string) => React.Dispatch<React.SetStateAction<string>>;
  }

  const defaultState = {} as IAppContext;

  const AppContext = createContext<IAppContext>(defaultState);
  export const useAppContext = (): IAppContext => {
    return useContext<IAppContext>(AppContext);
  };
const AppContextProvider = ({
    children,
    ...rest
  }: PropsWithChildren<{
    [key: string]: unknown;
  }>): JSX.Element => {

    const brandList: IBrand[] = brandListMock;
    const [token, setToken] = useState('');
    const state = {
      brandList,
      token,
      setToken,
      ...rest,
    } as IAppContext;
    
    return (
        <AppContext.Provider value={state}>
          {children}
        </AppContext.Provider>
      );
  }
  export default AppContextProvider;

