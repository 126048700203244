import { IconTypeMap } from '@mui/material'
import * as MUIcon from '@mui/icons-material'

type IconProps = IconTypeMap['props']

interface MIconProps extends IconProps {
  name: string | undefined
}

function MIcon(props: MIconProps) {
  const { name } = props
  const Icon = MUIcon[name as keyof typeof MUIcon]
  if (Icon == null) {
    return null
  }
  return <Icon {...props} />
}
export default MIcon