import React from 'react';
import './App.scss';
import Footer from './components/Footer';
import Header from './components/Header';
import RouteManager from './routes/RouteManager';
import AppContextProvider from './context/AppContext';
import { BrowserRouter } from 'react-router-dom';
import { IApp } from './context/models';
import { MsalProvider } from '@azure/msal-react';


const layoutResponse = {  "headerLogo": "", 
  "footer": 
          {    "footerLeft": '<p><strong>Forage Genetics International<br></strong>P.O. Box 339<br>Nampa ID 83563-0339</p>',    
               "footerCentre": '<p>Phone:<a href="tel:2084663568">(208) 466-3568</a><br>Toll Free: <a href="tel:8006355701">(800) 635-5701</a><br>Fax: <a href="tel:2084663684">(208) 466-3684</a><br><a href="mailto:info@foragegenetics.com">info@foragegenetics.com</a></p>',    
               "footerRight": '<div class="fr-view"><h6><a href="/legal-notice">Legal Notices</a></h6><h6><a href="https://www.landolakesinc.com/privacy-form" target="_blank">Your Privacy Choices</a></h6><h6 style="margin-top: 0"><a href="https://www.landolakesinc.com/privacy-policy" target="_blank">Privacy Notice</a></h6></div>'
          }
}; 
function App({ instance , nameOfUser }: IApp) {
  const accounts = instance.getAllAccounts();
  console.log('msal instance' , instance);

  return (
    <MsalProvider instance={instance}>
      <div className="App" data-testid="appTestId">
        <BrowserRouter>

          <AppContextProvider>
            <Header user={{ name: nameOfUser as string }} />
            <RouteManager />
            <Footer footer = {layoutResponse.footer} />
          </AppContextProvider>
        </BrowserRouter>
      </div>
    </MsalProvider>
  );
}

export default App;
