import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import {
  AuthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
  useMsalAuthentication,
} from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';

import { useAppContext } from '../context/AppContext';
import { APP_ROUTE_MAIN_PATHS } from './appRoutes';
const Dashboard = React.lazy(() => import('../pages/Dashboard'));
const Brand = React.lazy(() => import('../pages/Brand/Brand'));
const Search = React.lazy(() => import('../pages/Search/Search'));
const Error = React.lazy(() => import('../components/Error/Error'));

const Loader: React.FC<{ showLoader: boolean }> = ({ showLoader }) => {
    return (
      <div className="loader">
        {showLoader && <p>Loading...</p>}
      </div>
    );
  };


  const AuthProtectedRoutes = (): JSX.Element => {
    const { instance } = useMsal();
    const { result, acquireToken } = useMsalAuthentication(
      InteractionType.Redirect,
      { domainHint: process.env.DOMAIN_HINT },
    );
  
    const isAuthenticated = useIsAuthenticated();
  
    const { setToken } = useAppContext();
  
    useEffect(() => {
      if (!isAuthenticated) return;
  
      const obtainIdToken = async () => {
        await instance.initialize();
        const tokensData = await acquireToken();
        if (tokensData?.idToken) {
          setToken(tokensData?.idToken);
        }
      };
  
      obtainIdToken();
  
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [result?.idToken, isAuthenticated]);
  
    return (
      <AuthenticatedTemplate>
        <React.Suspense fallback={<Loader showLoader={true} />}>
          <Routes>
            <Route path="/" Component={Dashboard} />
            <Route path={APP_ROUTE_MAIN_PATHS.BRAND} Component={Brand} />
            <Route path={APP_ROUTE_MAIN_PATHS.SEARCH} Component={Search} />
            <Route Component={Error} />
          </Routes>
        </React.Suspense>
      </AuthenticatedTemplate>
    );
  };
  
  export default AuthProtectedRoutes;
  
