import React, { Fragment } from 'react';
import { Route, Routes } from 'react-router-dom';
import { APP_ROUTE_MAIN_PATHS } from './appRoutes';


interface IPublicRoutesConfig {
    [key: string]: {
      path: string;
      exact: boolean;
    };
  }
  
  export const publicRoutesConfig: IPublicRoutesConfig = {
    health: {
      path: APP_ROUTE_MAIN_PATHS.HEALTH_CHECK,
      exact: true,
    },
  };


  const PublicRoutes = () => {
    return (
      <Routes>
        <Route
          {...publicRoutesConfig.health}
          Component={Fragment}
        />
      </Routes>
    );
  };

export default PublicRoutes;
