import React from 'react'
import './Footer.scss'
import parse from 'html-react-parser';
 
 
export interface IFooterProps {
    footer : {
      footerLeft : string,
      footerCentre : string,
      footerRight : string
    }
  }

function Footer({ footer} : IFooterProps) {
    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="column left-column">
                    {parse(footer.footerLeft)}    
                </div>
                <div className="column centre-column">
                    {parse(footer.footerCentre)}
                </div>
                <div className="column right-column">
                    {parse(footer.footerRight)}     
                </div>
            </div>
            <div className="divider"></div>
            <div className="copyright">
                &copy; 2024 Forage Genetics International, LLC
            </div>
            </footer>
    );
};
export default Footer;
